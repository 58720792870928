.customer-page-ribon{
    width: 270px;
    height: 27px;
    background-image: url('assets/images/items/otameshi.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    span{
        font-size: 12px;
        font-weight: bold;
        position: relative;
        line-height: 27px;
        &:before{
            content: "";
            position: absolute;
            left: -8px;
            bottom: 0px;
            width: 1px;
            height: 15px;
            margin: auto;
            background-color: black;
            transform: rotate(-45deg);
        }
        &:after{
            content: "";
            position: absolute;
            right: -8px;
            bottom: 0px;
            width: 1px;
            height: 15px;
            margin: auto;
            background-color: black;
            transform: rotate(45deg);
        }
    }
}
.search-staff-team{
    width: 223px;
    height: 31px;
    background-image: url('assets/images/items/left-right.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    margin: 0 auto;
    line-height: 15px;
    span{
        font-size: 10px;
        position: relative;
    }
}
.challenge-campaign{
    width: 245px;    
    height: 70px;
    background-image: url('assets/images/items/ribon.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    line-height: 15px;
    margin: 0 auto !important;
    line-height: 22px;
    padding-top: 5px;
    box-sizing: border-box;
    span{
        font-size: 14px;
        font-weight: bold;
        position: relative;
        color: #fff;
    }
}
.challenge-campaign2{
    p{
        font-weight: bold;
        text-align: center;
        font-size: 13px;
        span{
            background-color: #f5222d;
            color: #fff;
            border-radius: 4px;
            padding: 5px 10px;
            box-sizing: border-box;
        }
    }
    .en-otamshi{
        font-size:12px;
    }
}
.search-staff-team2{
    width: 250px;
    height: 35px;
    background-image: url('assets/images/items/left-right.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    line-height: 15px;
    margin:0 auto!important;
    span{
        font-size: 14px;
        font-weight: bold;
        position: relative;
    }
}
.cocoro-point{
    width:100%;
    height: auto;
    padding: 10px 0;
    box-sizing: border-box;
    h3{
        font-size:14px;
        text-align: center;
    }
    p{
        font-size:18px;
        font-weight: bold;
        text-align: center;
        color:#5252eb;
        span{
        }
    }
}

@media screen {
    
}
@media screen and (min-width: 768px) {
    .header-logo{
        height:50px;
        img{
    
        }
        p{
            font-size: 12px;
            color:#000;
            .header-first{
                font-size: 10px;
            }
            .header-second{
                font-weight: bold;
            }
            .header-first-en{
                font-weight: bold;
            }
            .header-second-en{
                font-size: 10px;
                margin-top: -5px;
                display: block;
                margin-left: 5px;
            }
        }
    }
  }
  
  /* 768px未満の画面幅のスタイル */
  @media screen and (max-width: 767px) {
    .header-cover{
        padding-left: 0px;
        padding-right: 0px;
    }
    .header-logo{
        height:50px;
        img{
    
        }
        p{
            font-size: 10px;
            color:#000;
            width: 200px;
            .header-first{
                font-size: 10px;
            }
            .header-second{
                font-weight: bold;
            }
        }
    }
  }